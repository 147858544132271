<template>
  <div id="modal-calendar-event-data">
    {{/* MODAL INFO */}}
    <b-modal
        id="modal-data-event"
        class="p-0"
        hide-backdrop
        centered
        content-class="shadow"
        :hide-header="true"
        :hide-footer="true"
        body-bg-variant="light"
        body-text-variant="dark"
        @hidden="closeModal"
    >
      <div class="d-flex justify-content-between bg-info p-1">
        <div class="d-flex ml-2 mt-1">
          <h3 class="text-light">{{ selectedEvent.title }}</h3>
        </div>

        <div class="action mt-2">
          <b-icon class="" icon="pencil-square" font-scale="1.5" variant="light" v-on:click="editActionModal"></b-icon>
          <b-icon class="ml-2" icon="trash" font-scale="1.5" variant="light" v-on:click="deleteActionModal"></b-icon>
          <b-icon class="ml-2" icon="x-square" font-scale="1.5" variant="light" v-on:click="closeModal"></b-icon>
        </div>
      </div>
      <div class="data p-2">
        <div class="type-data d-flex">
          <b-icon class="ml-2" icon="tag" font-scale="1.5" variant="dark"></b-icon>
          <div class="ml-2">Type d'événement : {{ (selectedEvent.type) ? selectedEvent.type.name : '' }}</div>
        </div>
        <div class="type-data d-flex mt-2" v-if="selectedEvent.center">
          <b-icon class="ml-2" icon="geo-alt" font-scale="1.5" variant="dark"></b-icon>
          <div class="ml-2">Centre : <span>{{ centerData().name }}</span></div>
        </div>
        <div class="comment-data d-flex mt-2">
          <b-icon class="ml-2" icon="chat-square" font-scale="1.5" variant="dark"></b-icon>
          <div class="ml-2">Commentaire : {{ (selectedEvent.comment) ? selectedEvent.comment : '' }}</div>
        </div>
        <div class="shops-data" v-if="selectedEvent.shops && selectedEvent.shops.length > 0">
          <b-icon class="ml-2" icon="shop" font-scale="1.5" variant="dark"></b-icon> Magasin(s) :
          <div v-for="(shop, index) in selectedEvent.shops" :key="index">
            <b-badge class="p-2 c-pointer mt-2 ml-2" variant="dark">
              {{ shop.name }} | {{ shop.center.name }}
            </b-badge>
          </div>
        </div>
        <div class="event-data mt-2">
          <div class="d-flex" v-if="selectedEvent.date">
            <b-icon class="ml-2" icon="calendar2-date" font-scale="1.5" variant="dark"></b-icon>
            <div class="ml-2">{{ selectedEvent.date | moment('DD/MM/Y') }}</div>
          </div>
          <div class="d-flex" v-if="selectedEvent.start">
            <b-icon class="ml-2" icon="calendar2-date" font-scale="1.5" variant="dark"></b-icon>
            <div class="ml-2">
              Du
              <b>{{ selectedEvent.start | moment('DD/MM/Y') }}</b>
              au
              <b>{{ formatEndDate(selectedEvent.end) }}</b>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState} from "vuex";
export default {
  props: {
    selectedEvent: {
      type: Object,
      immediate: true
    }
  },
  data() {
    return {}
  },
  methods: {
    editActionModal()
    {
      this.$emit('emit-edit-action', this.selectedEvent)
    },
    deleteActionModal()
    {
      this.$emit('emit-delete-action')
    },
    closeModal()
    {
      this.$bvModal.hide('modal-data-event')
      this.$emit('emit-close-modal')
    },
    /*------------------------CENTER-------------------------*/
    centerData() {
      if (this.selectedEvent.center) {
        return this.$store.getters.getCenterById(this.selectedEvent.center)
      }
    },
    formatEndDate(endDate) {
      return moment(endDate).subtract(1, 'days').format('DD/MM/YYYY')
    }
  },
  computed: {
    ...mapState(['CenterModule']),
  },
}
</script>
